import React, { Fragment} from 'react';
import { graphql } from 'gatsby';
import Link from '../components/ExtendedLink';
import { FormattedMessage} from 'react-intl';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import Seo from '../components/Seo';
import Badge from '../components/badge';
import  BtnPrimary  from '../components/buttons/ButtonRounded';
import Boop from '../components/boop';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  FocusText,
  Text,
  Spacer,

  ActiviteItem,
  ActiviteBtnWrapper,
  ActivitesWrapper,

} from '../components/Elements';

export const nosActivitesQuery = graphql`
   query  activitesPageQuery($locale: String) {
    page: datoCmsNosActivitesPage(locale: {eq: $locale}) {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

   
    

    activites: allDatoCmsActivite(filter: {locale: {eq: $locale}}, sort: {order: ASC, fields: position}) {
      edges {
        node {
          id
          titre
          introduction
          pictogramme {
            url
          }
          guidapKey
          guidapKeyV2
          slug
          imagePrincipale {
            fluid(maxHeight: 480, forceBlurhash: false,imgixParams: { fm: "jpg" , fit: "crop", h: "480", w:"640", auto: "compress"}) {
            ...GatsbyDatoCmsFluid
            }
          }
          extraBoutonBilletterie {
            ... on DatoCmsBoutonGuidap {
              model {
                apiKey
              }
              id
              lienDuBouton
              codeGuidap
              codeGuidapV2
              texteDuBouton
            }
          }
        }
      }
    }
  }
`;
const NosActivitesPage = ({ data, pageContext }) => {

  const handleClick = (guidapKey) => window.GUIDAP.booking.call('bolleneaventure.guidap.co', `${guidapKey}?lang=${pageContext.locale}`)
  const { titre, contenu, seoMetaTags } = data.page;
  const { edges } = data.activites; //toutes les activités
   
  return (
    <Fragment>
      <Seo meta={seoMetaTags} />

      <PageWrapper>
       
        <PageInner>
          <PageTitle dangerouslySetInnerHTML={{ __html: titre }}/>
         <FocusText dangerouslySetInnerHTML={{ __html: contenu }} />
         <ActivitesWrapper>
                { _map(edges, (activite, i) => (
                  <ActiviteItem key={activite.node.id} className={i===0 && "large"}>
                     <Link to={`/nos-activites/${activite.node.slug}/`}>
                      <Img fluid={activite.node.imagePrincipale.fluid} alt={activite.node.titre}/>
                      <div className="description">
                        <h2 className="title">{activite.node.titre}</h2>
                        <div className="badge" ><Badge pictoURL={activite.node.pictogramme.url}/></div>
                        <Text dangerouslySetInnerHTML={{ __html: activite.node.introduction }}/>
                      </div>

                    </Link>
                    <ActiviteBtnWrapper>
                      <Boop scale={1.02} timing={100} >
                        <guidap-activity-reserve-button activity-uuid={activite.node.guidapKeyV2}><FormattedMessage id="book"/></guidap-activity-reserve-button>
                       </Boop>   
                      {/* <BtnPrimary as="a" href="#" onClick={ ()=>handleClick(activite.node.guidapKey)}><FormattedMessage id="book"/></BtnPrimary>*/} 
                     
                       {activite.node.extraBoutonBilletterie.map(block => (
                          <React.Fragment key={block.id}>
                            {block.model.apiKey === "bouton_guidap" && (    
                              <>  <Boop scale={1.02} timing={100} >
                              <guidap-activity-reserve-button activity-uuid={block.codeGuidapV2}>{block.texteDuBouton}</guidap-activity-reserve-button>
                            </Boop>   
                            {/*   <BtnPrimary as="a" href="#" onClick={()=>handleClick(block.codeGuidap)}>{block.texteDuBouton}</BtnPrimary>   */} 
                              </>
                              )
                            }
                          </React.Fragment>
                        ))}
                    </ActiviteBtnWrapper>
                  </ActiviteItem>
                ))}
          </ActivitesWrapper>
          <Spacer/>
        </PageInner>
        <Spacer/>
      </PageWrapper>
    </Fragment>
  );
}

export default NosActivitesPage;